







import { Vue, Component } from 'vue-property-decorator';
import MainHeader from '@/components/MainHeader.vue';

@Component({
    name: 'MainLayout',
    components: {
        MainHeader
    }
})
export default class MainLayout extends Vue {
}
