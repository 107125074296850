

















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
import EnglishIcon from '@/assets/images/English.png';
import ThailandIcon from '@/assets/images/Thailand.png';
// import IndonesiaIcon from '@/assets/images/Indonesia.png';
// import PhilippeIcon from '@/assets/images/Philippe.png';
// import MalaysiaIcon from '@/assets/images/Malaysia.png';
// import VietnamIcon from '@/assets/images/Vietnam.png';
// import ChinaIcon from '@/assets/images/China.png';

@Component({
    name: 'LangBox'
})
export default class extends Vue {
    $i18n: any;
    $route: any;
    $router: any;

    currentLang = '';

    get locale() {
        return AppModule.locale;
    }

    get localeText() {
        if (this.locale === 'en') {
            return 'English'
        }
        if (this.locale === 'id') {
            return 'Indonesia'
        }
    }

    languageList: any = [
        {
            localeLangDesc: 'English',
            langCode: 'en',
        },
        {
            localeLangDesc: 'Indonesia',
            langCode: 'id',
        },
    ];

    async changeLang(langCode: string) {
        this.$i18n.locale = langCode;
        AppModule.SET_LANGUAGE(langCode);
    }
}
