























import { Vue, Component } from 'vue-property-decorator';
import LangBox from '@/components/LangBox.vue'

@Component({
    name: 'MainHeader',
    components: {
        LangBox
    }
})
export default class MainHeader extends Vue {
    activeIndex: number | null = null;

    disableTransition: boolean = false

    get btnMenus() {
        return [
            {
                name: this.$t('ourBusiness'),
                path: '/ourBusiness'
            },
            {
                name: this.$t('ourService'),
                path: '/ourService'
            },
            {
                name: this.$t('gallery'),
                path: '/gallery'
            },
            {
                name: this.$t('aboutUs'),
                path: '/aboutUs'
            }
        ]
    }

    created() {
        this.matchRoute()
    }

    matchRoute() {
        const path = this.$route.path
        this.btnMenus.forEach((item, index) => {
            if (item.path === path) {
                this.activeIndex = index
            }
        })
    }

    toPage(path: string, index: number) {
        if (this.activeIndex !== index) {
            this.disableTransition = true;
            setTimeout(() => {
                this.disableTransition = false;
            }, 30)
        }
        this.activeIndex = index;
        this.$router.push({ path });
    }

    applyOnline() {
        // @ts-ignore
        this.$parent.$parent.dialogVisible = true
    }

    goHome() {
        this.activeIndex = null
        this.$router.push('/')
    }
}
